module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lucferbux Web","short_name":"Lucferbux","start_url":"/","background_color":"#F2F6FF","theme_color":"#CA8F36","theme_color_in_head":false,"display":"standalone","description":"Personal PWA","icon":"static/images/logos/logo-icon.svg","icon_options":{"purpose":"any maskable"},"prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.lucferbux.lucferbux"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1503e572530854a3e4d3afcb2460ea68"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
